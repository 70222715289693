const config = {
  apiGateway: {
    REGION: 'us-east-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://0ecqhjmtr2.execute-api.us-east-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://nvua3mhn0h.execute-api.us-east-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://ukbwye0p9a.execute-api.us-east-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.1',
    HOSTNAME: 'https://locations.vale.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.vale.forwoodsafety.com',
    WEBSOCKET: 'wss://ytsci10m9h.execute-api.us-east-2.amazonaws.com/prod'
  },
};

export default config;